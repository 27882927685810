.layout {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.header {
    background-color: #f2f2f2;
    margin-top: 10px;
}

.headerContainer {
    display: flex;
    justify-content: left;
    align-items: center;

}

.headerTitleContainer {
    display: flex;
    align-items: center;
    margin-left: 14px;
    text-decoration: none;
}

.headerTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: flex-end;
    color: #242424;
}

.headerIcon {
    height: 32px;
    width: auto;
    margin-left: 36px;
}

.shareButtonContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-right: 20px;
}

.shareButton {
    color: #FFFFFF;
}

.shareButtonText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.urlTextBox {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #707070;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}

.copyButtonContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    gap: 4px;
    background: #FFFFFF;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}

.copyButtonContainer:hover {
    cursor: pointer;
    background: #D1D1D1;
}

.copyButton {
    color: #424242;
}

.copyButtonText {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
}

@media (max-width: 480px) {
    .headerTitleContainer {
        margin-left: 4px;
    }

    .headerIcon {
        margin-left: 26px;
    }

    .headerTitle {
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: flex-end;
        color: #242424;
    }
}