.container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 20px
}

.chatRoot {
    flex: 1;
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    margin-left: 20px;
    margin-right: 20px;
    gap: 5px;
}

.chatContainer {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    background: radial-gradient(108.78% 108.78% at 50.02% 19.78%, #FFFFFF 57.29%, #f4e6e6 100%);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    overflow-y: auto;
    max-height: calc(100vh - 100px);
}

.chatEmptyState {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.chatEmptyStateTitle {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    display: flex;
    align-items: flex-end;
    text-align: center;
    line-height: 24px;
    margin-top: 36px;
    margin-bottom: 0px;
}

.chatEmptyStateSubtitle {
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    align-items: flex-end;
    text-align: center;
    letter-spacing: -0.01em;
    color: #616161;
    max-width: 800px;
}

.chatIcon {
    height: 62px;
    width: auto;
}

.chatMessageStream {
    flex-grow: 1;
    max-width: 1028px;
    width: 100%;
    overflow-y: auto;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
}

.chatMessageUser {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 12px;
}


.chatMessageUserMessage {
    display: flex;
    padding: 20px;
    background: #fadfdf;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #242424;
    order: 0;
    flex-grow: 0;
    white-space: pre-wrap;
    word-wrap: break-word;
    max-width: 80%;
}

.chatMessageGpt {
    margin-bottom: 12px;
    max-width: 80%;
    display: flex;
}

.chatMessageError {
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgba(182, 52, 67, 1) 1px 1px 2px, rgba(182, 52, 67, 1) 0px 0px 1px;
    color: #242424;
    flex: none;
    order: 0;
    flex-grow: 0;
    max-width: 800px;
    margin-bottom: 12px;
}

.chatMessageErrorContent {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;
    word-wrap: break-word;
    gap: 12px;
    align-items: center;
}

.chatMessageMetadata {
    padding: 20px;
    border-radius: 8px;
    box-shadow: rgb(26, 55, 0) 1px 1px 2px, rgb(26, 55, 0) 0px 0px 1px;
    color: #242424;
    flex: none;
    order: 0;
    flex-grow: 0;
    max-width: 800px;
    margin-bottom: 12px;
}

.chatMessageMetadataContent {
    font-family: "Segoe UI";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    white-space: pre-wrap;
    word-wrap: break-word;
    gap: 12px;
    align-items: center;
}

.chatInput {
    position: sticky;
    flex: 0 0 100px;
    padding-top: 12px;
    padding-bottom: 24px;
    padding-left: 24px;
    padding-right: 24px;
    width: calc(100% - 100px);
    max-width: 1028px;
    margin-bottom: 50px;
    margin-top: 8px;
}

.clearChatBroom {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 7px;
    top: 13px;
    color: #FFFFFF;
    border-radius: 4px;
    z-index: 1;
}

.clearChatBroomNoCosmos {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 7px;
    top: 66px;
    color: #FFFFFF;
    border-radius: 4px;
    z-index: 1;
}

.newChatIcon {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 40px;
    height: 40px;
    left: 7px;
    top: 66px;
    color: #FFFFFF;
    border-radius: 4px;
    z-index: 1;
}

.stopGeneratingContainer {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 5px 16px;
    gap: 4px;
    position: absolute;
    width: 161px;
    height: 32px;
    left: calc(50% - 161px/2 + 25.8px);
    bottom: 116px;
    border: 1px solid #D1D1D1;
    border-radius: 16px;
}

.stopGeneratingIcon {
    width: 14px;
    height: 14px;
    color: #424242;
}

.stopGeneratingText {
    width: 103px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    color: #242424;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.citationPanel {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px 16px;
    gap: 8px;
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
    flex: auto;
    order: 0;
    align-self: stretch;
    flex-grow: 0.3;
    max-width: 30%;
    overflow-y: scroll;
    max-height: calc(100vh - 100px);
}

.citationPanelHeaderContainer {
    width: 100%;
}

.citationPanelHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.citationPanelDismiss {
    width: 18px;
    height: 18px;
    color: #424242;
}

.citationPanelDismiss:hover {
    background-color: #D1D1D1;
    cursor: pointer;
}

.citationPanelTitle {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #323130;
    margin-top: 12px;
    margin-bottom: 12px;
} 

.citationPanelTitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

.citationPanelContent {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
} 

a {
    padding-left: 5px;
    padding-right: 5px;
}

.viewSourceButton {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #115EA3;
    flex-direction: row;
    align-items: center;
    padding: 4px 6px;
    gap: 4px;
    border: 1px solid #D1D1D1;
    border-radius: 4px;
}

.viewSourceButton:hover {
    text-decoration: underline;
    cursor: pointer;
}

.dotbody {
    margin: 0;
    padding: 0;
    background: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }

.dot1, .dot2, .dot3 {
    background: #fff;
    width: 5px;
    height: 5px;
    border: double;
    border-color: #FD6262;
    border-radius: 50%;
    margin: 10px;
  }
  
  .dot1 {
    animation: jump 1.6s -0.32s linear infinite;
    background: #FD6262;
  }
  
  .dot2 {
    animation: jump 1.6s -0.16s linear infinite;
    background: #FD6262;
  }
  
  .dot3 {
    animation: jump 1.6s linear infinite;
    background: #FD6262;
  }
  
  @keyframes jump {
    0%, 80%, 100% {
      transform: scale(0);
    }
    40% {
      transform: scale(2.0);
    }
  }

@media (max-width: 480px) {
    .chatInput {
        width: 90%;
        max-width: 90%;
    }

    .newChatIcon {
        left: 0px;
    }

    .clearChatBroom {
        left: 0px;
    }

    .clearChatBroomNoCosmos {
        left: 0px;
    }

    .chatEmptyStateTitle {
        line-height: 36px;
    }

    .citationPanel {
        max-width: 100%;
    }

    .chatEmptyStateTitle {
        font-weight: 600;
        font-size: 20px;
    }
    
    .chatEmptyStateSubtitle {
        margin-top: 10px;
        padding-left: 10px;
        padding-right: 10px;
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        
    }

    .chatLink{
        font-size: 10px;
    }

    .chatIcon {
        display: none;
    }

    .chatMessageUserMessage {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
    }

    .chatMessageMetadataContent {
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
    }

    .chatMessageErrorContent {
        font-weight: 300;
        font-size: 10px;
        line-height: 16px;
    }
}